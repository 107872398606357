$lineWidth: 100%;

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');@import "colors";
@import "animations";

* {
	box-sizing: border-box;
	font-family: 'Raleway', sans-serif;
}

html, body {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
}

body {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
	flex-direction: column;
	font-size: 87.5%;
	font-weight: 500;
	color: #333;
	hr{
		width: 100%;
		height: 1px;
		margin: 2rem 0;
		border: none;
		background-image: linear-gradient(to right, #666 33%, rgba(255, 255, 255, 0) 0%);
		background-position: bottom;
		background-size: 6px 2px;
		background-repeat: repeat-x;
	}
	h1, h2, h3, h4, h5, h6{
		margin: 0;
	}
	h2{
		font-size: 3rem;
		font-weight: 500;
		text-transform: uppercase;
		font-style: italic;
		color: #999;
		+ p{
			margin: 1rem 0 0;
		}
	}
	a, .link{
		color: $blue;
		transition: all 0.35s;
		cursor: pointer;
		&:hover{
			text-decoration: underline;
			color: $lightblue;
		}
	}
	h3{
		//font-style: italic;
		font-size: 1.4rem;
		font-weight: 400;
		margin: 0 0 1rem;
		color: #666;
	}
	h4{
		margin: 0 0 2rem;
	}
	p, ul, li {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}
	p{
		margin: 0 0 1rem;
	}
	button{
		text-transform: uppercase;
		border: none;
		background: $blue;
		background: $blueGradient;
		height: 2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		cursor: pointer;
		transition: all 0.35s;
		&:hover{
			background: $blueGradientHover;
		}
	}
	#nautilus-app {
		display: flex;
		flex: 1;
		width: 100%;

		@import "navigation";

		.container {
			width: 80%;
			max-width: 1200px;
			margin: 0 auto;
		}
		.popup{
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 1000;
			.background{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: $blueGradient;
				opacity: 0.9;
			}
			.inner{
				position: relative;
				background-color: white;
				padding: 2rem;
				max-width: 80%;
				box-shadow: 0 1rem 4rem -2rem #000;
			}
			h1, h2, h3, h4, h5, h6{
				margin: 2rem 0 1rem;
			}
			ul, a{
				+ p{
					margin-top: 1rem;
				}
			}
			ul{
				padding: 0 0 0 2rem;
				li{
					list-style-type: square;
				}
			}
		}
		.scroll-wrapper {
			display: flex;
			flex: 1;
			width: 100%;

			.main{
				display: flex;
				flex: 1;
				flex-direction: column;
				width: 100%;
				height: 100%;
				.section {
					display: flex;
					flex: 1;
					align-items: center;
					justify-content: center;
					background-image: $whiteGradient;
					min-height: 100vh;
					@import "welcome";
					@import "contact";
				}
			}
		}
	}
}
