.header {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;
	transition: all 0.35s;
	color: white;
	&.scrolled{
		background-color: rgba(255,255,255,0.8);
		color: $blue;
		.navigation {
			&:after {
				opacity: 0;
			}

			.active{
				.nav {
					box-shadow: inset 0 0 0 1px lighten($blue, 50%);
				}
			}
			.meet {
				&:hover {
					color: $blue;
				}
				&:hover::before {
					border-top-color: $blue;
					border-right-color: $blue;
				}
				&:hover::after {
					border-bottom-color: $blue;
					border-left-color: $blue;
				}
			}
			.divider{
				border-color: $blue;
				opacity: 0.5;
			}
			p.headlogo{
				svg{
					g, path{
						fill: $lightblue;
						opacity: 1;
					}
				}
				&:hover{
					svg{
						g, path{
							fill: $blue;
							opacity: 1;
						}
					}
				}
			}
		}
	}
	.navigation {
		display: flex;
		justify-content: space-between;
		height: 4rem;
		align-items: center;
		.nav {
			background: none;
			border: 0;
			box-sizing: border-box;
			box-shadow: inset 0 0 0 1px transparent;
			position: relative;
			vertical-align: middle;
			padding: 0.5rem 1rem;
			&::before,
			&::after {
				box-sizing: inherit;
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
			}
		}
		.draw {
			transition: color 0.25s;
			&::before,
			&::after {
				border: 1px solid transparent;
				width: 0;
				height: 0;
			}
			&::before {
				top: 0;
				left: 0;
			}
			&::after {
				bottom: 0;
				right: 0;
			}
			&:hover {
				color: transparent;
			}
			&:hover::before,
			&:hover::after {
				width: 100%;
				height: 100%;
			}

			&:hover::before {
				border-top-color: transparent;
				border-right-color: transparent;
				transition:
						width 0.25s ease-out,
						height 0.25s ease-out 0.25s;
			}
			&:hover::after {
				border-bottom-color: transparent;
				border-left-color: transparent;
				transition:
						border-color 0s ease-out 0.5s,
						width 0.25s ease-out 0.5s,
						height 0.25s ease-out 0.75s;
			}
		}
		.active{
			.nav {
				box-shadow: inset 0 0 0 1px rgba(255,255,255,0.5);
			}
		}
		.meet {
			&:hover {
				color: white;
			}
			&::after {
				top: 0;
				left: 0;
			}
			&:hover::before {
				border-top-color: white;
				border-right-color: white;
			}
			&:hover::after {
				border-bottom-color: white;
				border-left-color: white;
				transition: // Animate height first, then width
						height 0.25s ease-out,
						width 0.25s ease-out 0.25s;
			}
		}
		.languages{
			li{
				opacity: 0.5;
				cursor: pointer;
				&.active{
					opacity: 1;
				}
			}
		}
		p{
			margin: 0;
			display: flex;
			z-index: 10;
			&.headlogo{
				width: 10rem;
				height: 4rem;
				margin-left: 1rem;
				cursor: pointer;
				opacity: 0;
				animation: fadeIn 2s 2s forwards;
				svg{
					g, path{
						transition: all 0.35s;
						fill: white;
						opacity: 0.5;
					}
				}
				&:hover{
					svg{
						g, path{
							opacity: 1;
						}
					}
				}
			}
		}
		> div{
			position: relative;
			display: flex;
			z-index: 10;
			p{
				margin: -6rem 2rem 0;
				animation: fadeInDown 2s 2s forwards;
			}
		}
		&:after {
			content: "";
			width: 100%;
			//height: 1px;
			//background-image: linear-gradient(to right, white 33%, rgba(255, 255, 255, 0) 0%);
			//border-bottom: 1px solid white;
			box-shadow: 0 1rem 2rem -2rem #000;
			//background-position: bottom;
			//background-size: 6px 2px;
			//background-repeat: repeat-x;
			height: 5rem;
			position: absolute;
			bottom: 0;
			right: 100%;
			animation: drawLine 1s 2s forwards;
		}
		.divider{
			margin: 0 1rem;
			border-right: 1px solid white;
		}
		p:not(.headlogo) {
			margin-top: -6rem;
			animation: fadeInDown 2s 2s forwards;
		}

		ul {
			display: flex;
			li {
				display: flex;
				margin-top: -6rem;
				animation: fadeInDown 2s 2s forwards;
				cursor: pointer;
				align-items: center;
				+ li {
					margin-left: 1rem;
				}
			}
		}
	}
}
