@keyframes animateDot {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes animateLine {
	from {
		opacity: 0;
		width: 0;
	}
	to {
		opacity: 1;
		width: $lineWidth;
	}
}
@keyframes animateLineDot {
	from {
		right: calc((100% - #{$lineWidth}) / 2 + #{$lineWidth});
		width: 0;
		height: 0;
		margin: 0.5rem 0 0 0;
		border-color: transparent;
	}
	to {
		right: calc((100% - #{$lineWidth}) / 2);
		width: 1rem;
		height: 1rem;
		margin: 0 -0.5rem 0 0;
		border-color: white;
	}
}
@keyframes animateTitle {
	from {
		opacity: 0;
		transform: translateY(-2rem);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}
@keyframes animateSubTitle {
	from {
		opacity: 0;
		transform: translateY(-2rem);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes drawLine {
	from {
		right: 100%;
	}
	to {
		right: 0;
	}
}

@keyframes fadeInDown {
	from {
		margin-top: -6rem;
	}
	to {
		margin-top: 0;
	}
}
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes bounce {
	0%   { transform: scale(1,1)      translateY(0); }
	10%  { transform: scale(1.1,.9)   translateY(0); }
	30%  { transform: scale(.9,1.1)   translateY(-20px); }
	50%  { transform: scale(1.05,.95) translateY(0); }
	57%  { transform: scale(1,1)      translateY(-7px); }
	64%  { transform: scale(1,1)      translateY(0); }
	100% { transform: scale(1,1)      translateY(0); }
}
